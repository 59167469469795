@import "src/global-styles/index";

.bodyContainer {
  /* width: calc(100% - 300px);*/
  margin: 2rem 1.5rem 2rem;
}

@media (min-width: $screen-large){
  .bodyContainer {
    /* width: calc(100% - 300px);*/
    margin: 2rem 1rem 10rem 18rem;
  }

  .bodyContainer.questions {
    margin-left: 2rem;
  }

  .toggleStyle{
    display: block;
  }
}

@media (min-width: $screen-extra-large){
  .bodyContainer {
    margin-left: 300px;
    margin-right: 50px;
  }
}
