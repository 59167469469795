.IconHeader{

}

.IconHeader-header-container{
  width: 100%;
}

.IconHeader-leave-icon{
  width: 35px;
  float: right;
  margin-right: 1rem;
  margin-top: 1rem;
}
