@import "src/global-styles/index";

.MeetingTimeButton {
  width: 100%;
  margin: 1%;
}

.MeetingTimeButton-is-active {
  border: solid 1px $brockhaus-red;
  width: 100%;
  margin: 1%;
}

@media (min-width: 997px) {
  .MeetingTimeButton {
    width: 50%;
    margin: 1%;
  }

  .MeetingTimeButton-is-active {
    width: 50%;
    margin: 1%;
  }
}