@import "src/global-styles/index";

.card {
  .image {
    .icon {
      height: auto;
      width: 40px;
    }
  }
}
.App h2 {
  font-family: GilroyLight, sans-serif;
  color: $brockhaus-red;
  font-size: 2rem;
  margin-right: 0.5rem;
}

.row{
  .column{
    margin: 2rem 0;
    .card {
      display: flex;
      .image {
        height: 20%;
        width: auto;
        margin-right: 2rem;
      }

      .body {
        margin-top: 0.2rem;
        display: flex;

        button{
          margin-top: 1rem;
          border: solid black 1px;
          display: none;
        }
      }
    }
    .btnMobileBag{
      display: block;
      margin-top: 1rem;
      border: solid $brockhaus-red 1px;
    }
  }
}

.smallBagBtn{
  border: solid $brockhaus-red 1px;
}

.link-container{
  text-decoration: none;
}

@media screen and (min-width: $screen-large) {
  .App h2 {
    font-family: GilroyLight, sans-serif;
    color: $brockhaus-red;
    font-size: 2rem;
    margin-right: 0;
  }

  .Dashboard {
    max-width: 1180px;
    margin:auto;

    .Dashboard-container {
      width: 100%;
      margin: auto;
      .card {
        .image {
          height: 40%;
          width: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0;
          .icon {
            height: auto;
            width: 80px;
          }
        }

        .body {
          margin-top: 1rem;
          display: block;
          button{
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-top: 2rem;
            border: solid $brockhaus-red 1px;
          }

          button:hover{
            background: none;
            border-color: #931933;
          }

          button:active{
            border-color: #6e1326;
          }

        }
      }
      .btnMobileBag{
        display: none;
      }
    }

    /* Float four columns side by side */
    .column {
      float: left;
      width: 30%;
      padding: 0 10px;
    }

    /* Remove extra left and right margins, due to padding in columns */
    .row {

    }

    /* Clear floats after the columns */
    .row:after {
      content: "";
      display: table;
      clear: both;
    }

    /* Style the counter cards */
    .card {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
      padding: 16px;
      text-align: center;
      background-color: #f1f1f1;
      height: 15rem;
      display: flex;
      flex-direction: column;
      justify-content: normal;
      align-items: center;
    }

    /* Responsive columns - one column layout (vertical) on small screens */
    @media screen and (max-width: $screen-medium) {
      .column {
        width: 100%;
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  p {
    font-family: SourceSansProRegular, sans-serif;
  }
}