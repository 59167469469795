@import "src/global-styles/index";

.Profile-logo-container{
  width: 100%;
  height: 43vh;
}

.Profile-name-container{
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 2rem;

  @media screen and (min-width: $screen-large) {
    margin-bottom: 4rem;
  }
}

.Profile-settings-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Profile-button-container{
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
