@import "src/global-styles/index";

.UpcomingMeetings{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (min-width: $screen-large) {
    width: 100%;
    display: block;
  }
}

.UpcomingMeetings-container{
  width: 80%;
  max-width: 700px;
  padding-bottom: 2em;
  margin:auto;
}

.UpcomingMeetings-header{
  font-size: 1.6em;
  margin-top: 3rem;
}

.UpcomingMeetings-subheader{
  font-size: 1.2em;
  margin-top:5%;
  color: #575756;
  font-family: SourceSansProRegular, sans-serif;
}