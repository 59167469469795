@import "src/global-styles/index";

.ProfileForm {
  margin-bottom: 10rem;
  .DropdownQuestion{
    input{
      background: #ffffff;
    }
    input::placeholder{

    }
  }

  .DropdownQuestion{
    @media screen and (min-width: $screen-large){
      display: flex;
      justify-content: space-between;

      .MuiTextField-root{
        .MuiInputBase-root{
          width: 100%;
        }
      }

      .inLineToggle{
        width: 75%;
        max-width: 21rem;
        padding-right: 1rem;
        vertical-align: top;
      }

      .inLineToggleHelper{
        float: right;
        width: 100%;
        position: relative;
      }

      .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon, .MuiFormControl-root{
        width: 100%;
        vertical-align: middle;
        position: relative;
      }
    }
  }
}

.profile-form-button-container{
  max-width: 500px;
  margin:auto;
}

.multiSelectContainer {
  position: relative;
  text-align: left;

  li:hover {
    background: whitesmoke;
    color: black;
    cursor: pointer;
  }
}

@media screen and (min-width: $screen-large) {
  .QuestionContainer{
    width: 80%;
    margin:auto;
  }
  .multiSelectContainer {
    position: relative;
    text-align: left;
  }
}

.multiselect-container.multiSelectContainer .searchWrapper {
  border-radius: 0;
}

.Multi-select-container {
  .labelWrapper {
    background-color: #f0f0f0;
    padding: .3rem;
    width: 70%;
    top: 8px;
    left: 8px;
    position: relative;
    z-index: 999;

    label {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      color: rgba(0, 0, 0, 0.54);
      font-size: .8rem;
    }
  }

  .chip {
    align-items: center;
    background: rgba(0, 0, 0, 0.08);
    border-radius: 11px;
    color: black;
    display: inline-flex;
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 5px;
    margin-right: 0;
    margin-left: 5px;
    padding: 4px 10px;
    font-family: SourceSansProRegular, sans-serif;
  }

  input {
    background: transparent;
    border: none;
    margin-top: 5px;
    margin-left: 0;
    padding-left: 10px;
    max-height: 42px;
    color: currentColor;
    font: inherit;
  }

  input::placeholder {
    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    font: inherit;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
  }

}

.highlightOption {
  background: rgba(0, 0, 0, 0.08);
  color: black;
}

.closeIcon {
  cursor: pointer;
  float: right;
  height: 13px;
  margin-left: 5px;
  width: 13px;
  color: black;
}

.searchBox {
  width: auto;
  font-family: SourceSansProRegular, sans-serif;
  font-size: 1.1rem;
  overflow-y: clip;
}

.searchWrapper {
  border: 1px solid #c4c4c4;
  border-radius: 0;
  min-height: 22px;
  padding: 5px;
  position: relative;
}
