@import "src/global-styles/reset";

@font-face {
  font-family: "GilroyRegular";
  src: url('../resources/fonts/Gilroy-Regular.otf') format("trueType");
  font-weight: normal;
}

@font-face {
  font-family: "GilroyLight";
  src: url('../resources/fonts/Gilroy-Light.otf') format("trueType");
  font-weight: normal;
}

@font-face {
  font-family: "GilroyMedium";
  src: url('../resources/fonts/Gilroy-Medium.otf') format("trueType");
  font-weight: normal;
}

@font-face {
  font-family: "SourceSansProRegular";
  src: url('../resources/fonts/SourceSansPro-Regular.otf') format("trueType");
  font-weight: normal;
}

$brockhaus-red: #B81F40;
$screen-extra-large: 1650px;
$screen-large: 997px;
$screen-medium: 500px;
$screen-small: 300px;

.view {
  width: 100%;
  display: flex;
  flex-direction: column;
}

input {
  background-color: #ffffff;
  white-space: nowrap;
  padding: 14px;
  border: 1px solid #F8E9EC;
  color: gray;

  letter-spacing: 0.015em;
  font-family: GilroyLight, sans-serif;
  font-style: normal;
  font-size: 16px;
  margin: 5px;
}

input:focus {
  outline: none;
}

.editListTitle {
  font-weight: 500;
  font-size: 1.3rem;
  cursor: pointer;
  padding: 20px 0 20px 0;
}

.CSVUploadTitle{
  font-weight: 500;
  font-size: 1.3rem;
}

.CSVUploadContainer {
  padding: 15px 20px;
}

.CSVUploadContainer div{
  margin: 25px;
}

.CSVUploadContainer .voucherCountState {
  padding-bottom: 2%;
}

#CSVUploadArea {
  display: flex;
  flex-wrap: wrap;

  p {
    width: 100%;
  }

  input {
    min-width: min-content;
    margin-left: 0;
  }

  button {
    position: relative;
    float: right;
    width: min-content;
    margin: 5px 5px 5px 0;
  }
}

.customizeDimensionHeader {
  padding: 1%;
}

.optionsListContainer {
  section {
    padding-left: 20px;
  }
}


