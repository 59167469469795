@import "src/global-styles/reset";

.ShowMeeting{
  background-color: #efefef;

  width: 76%;
  margin: 5% 0 5% 10%;
  padding: 2%;

  border: 1px solid #a0a096;

  display: flex;
}


.ShowMeeting-text-container{
  width: 70%;
  text-align: left;
}

.ShowMeeting-icon-container{
  width: 30%;

}

.ShowMeeting-text{
  margin: 5%;
}

.ShowMeeting-hamburgerIcon{
  width: 40px;
  margin-top: 15%;
}

.ShowMeeting-closeIcon{
  width: 20px;
  float: right;
  margin: 2%;
}
