@import "src/global-styles/index";

.SelectMeetingData {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin:auto;
}

.ChooseDate-infoText {
  margin: 10%;
  font-size: 1.2em;
  text-align: center;
}

@media screen and (min-width: $screen-large){
  .SelectMeetingData {
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ChooseDate-infoText {
    margin: 1.5rem 0;
    font-size: 1.2rem;
    text-align: left;
  }

  .ChooseTime-segment-control {
    width: 100%;
    display: block;
    margin-top: 2rem;
  }
}

.ChooseDate-date-text {
  margin: 10%;
  font-size: 1.3em;
}

.ChooseDate-button {
  width: 60%;
  padding: 2% 0 2% 0;
  margin: 10%;
  font-size: 1.5em;
}

.ChooseTime-segment-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.ChooseTime-segment-control-segment {
  border: solid 2px;
  padding: 2%;
  margin: 2%;
  width: 50%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.ChooseTime-segment-control-segment:focus {
  background-color: $brockhaus-red;
  color: white;
  border-color: black;
}

.ChooseTime-segment-control-segment:hover {
  background-color: $brockhaus-red;
  border-color: black;
  color: white;
}