@import "src/global-styles/index";

.ShowVouchers{
  background-color: #f0f0f0;
  width: 100%;
  max-width: 700px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin:auto;
}

.ShowVouchers h2 {
  margin-top: 1.5rem;
}

.ShowVoucher-text{
  margin: 5%;
  margin-bottom: 3%;
  font-size: 1.2em;
  text-align: center;
}

.ShowVoucherList{
  margin: 1% 15% 3% 15%;
  font-size: 2.2em;
  border: solid;
  width: 70%;
  color: $brockhaus-red;
  overflow-y: auto;
  height: 40%;
}

.voucherItem{
  font-size: 0.7em;
  color: #575756;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.voucherItem:nth-of-type(even){
  background: lightcoral;
}


