.GeneralPopUp {
  margin: 0 auto;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5)
}

.GeneralPopUp-container {
  width: 80%;
  height: 50%;
  max-width: 450px;
  max-height: 400px;
  opacity: 100%;
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.GeneralPopUp-text{
  max-width: 380px;
  line-height: 1;
}

.DeleteMeetingPopUp-meeting-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size:  1.2em;
  margin: 8%;
  line-height: 1;
}

.DeleteMeetingPopUp-button-container{
  width: 100%;
}

.DeleteMeetingPopUp-delete-button{
  margin-top: 1%;
}
