

.voucherListTable{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1em;
}

th, td{
  text-align: left;
  background: white;
  padding: 1em !important;
  color: gray;
}
