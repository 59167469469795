@import "src/global-styles/index";

.Information {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin:auto;
  .FAQ-title {
    cursor: pointer;
  }
}

.Information-info-text {
  margin-top: 10%;
  line-height: 18px;
  margin-bottom: 4rem;
  hyphens: auto;
  text-align: justify;
}

@media screen and (min-width: $screen-large){
  .Information-info-text {
    margin-top: 1.5rem;
    line-height: 18px;
    margin-bottom: 3.5rem;
  }
  .Information {
    width: 90%;
  }
}