.DropdownQuestion{
  margin-bottom: 2rem;
  width: 100%;
}

.DropdownQuestion-question{
  margin-bottom: 1rem;
}

.DropdownQuestion fieldset {
  border-radius: 0;
}

.DropdownQuestion .MuiFormControl-root {
  width: 100%;
}