@import "src/global-styles/index";

html {
  overflow-y: scroll;
}

//Übergeordneter Container in dem alle Components liegen
.App {

  font-family: SourceSansProRegular, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0;

  //Die Mobile View, die auf jedem Gerät angezeigt wird
  .Screen {

    background-color: #ffffff;
  }

  .toggleStyle{
    display: none;
  }

  h1 {
    font-family: GilroyLight, sans-serif;
    color: $brockhaus-red;
    font-size: 2.5rem;
  }

  h2 {
    font-family: GilroyLight, sans-serif;
    color: $brockhaus-red;
    font-size: 2rem;
  }

  h3 {
    font-family: GilroyRegular, sans-serif;
    color: $brockhaus-red;
    font-size: 1.5rem;
  }

  h4 {
    font-family: GilroyRegular, sans-serif;
    color: $brockhaus-red;
    font-size: 1rem;
    height: 1.4rem;
    margin-top: 6px;
  }

  p {
    color: #575756;
    font-family: SourceSansProRegular, sans-serif;
  }

  summary {
    color: #575756;
    font-family: SourceSansProRegular, sans-serif;
  }

  text {
    color: #575756;
  }

  :host {

    --color-text-primary: var(--color-secondary-2-10);
    --color-disabled: var(--color-secondary-2-13);

    // Font
    --font-1-size: 1.2rem;

    // Border
    --border-radius-none: 0;
    --border-radius-round: 50%;
    --border-radius-rounded: 0.15em;

  }
}

Button {
  white-space: nowrap;
  padding: 14px;
  border: 1px solid #F8E9EC;
  color: $brockhaus-red;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  font-family: GilroyMedium, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

Button:hover:enabled {
  background-color: whitesmoke;
  border: 1px solid #F1D2D9;
  color: #931933;
}

Button:active:enabled {
  background-color: darkgray;
  border: 1px solid #E3A4B3;
  color: #6E1326;
}

Button:disabled {
  border: 1px solid #F3F3F3;
  color: #575756;
}

//reset the stroke Button style from above.
.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator, .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty{
  background-image: none;
  background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}
