@import "src/global-styles/index";

.AppHeader{
  background-color: #efefef;
  padding: 1rem 0 1rem;

  .AppHeader-Wrapper{
    .flexBox{
      display: flex;
      justify-content: center;
      position: relative;
    }
    @media screen and (min-width: $screen-large){
      .flexBox{
        justify-content: left;
      }
    }
  }
}