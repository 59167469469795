.testmailButton{
  width: 50%;
  min-width: min-content;
  justify-self: center;
  padding: 1em;
}
.testMailContainer{
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
  margin-top: 20px;
}

.adminPanelContainer {

}

.adminPanelContainer .header {
  margin-bottom: 5%;
}
